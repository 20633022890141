import { UserData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../components/View';
import Form from '../../../../containers/Admin/Form';
import params from '../../../../params/user/index.json';
import TemplateAdmin from '../../../../templates/admin';
import requireUser from '../../../../utils/requireUser';

const PageAdminUsersUpdate: FC<PageProps> = props => {
  const {
    params: { id },
  } = props;

  if (!id) {
    return <div>l utilisateur n existe pas</div>;
  }

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          docId={id}
          itemPathnamePrefix="/admin/users/"
          model={new UserData({ params })}
          name="user"
          type="update"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminUsersUpdate, 'admin');
